import { merge } from "theme-ui"
import tailwind from '@theme-ui/preset-tailwind'
import { candy } from "../styles/colors"

const baseFonts = {
  sans: 'omnes-pro,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  serif: 'freight-text-pro,"Times New Roman",Times,serif',
  mono: '"Courier New", Courier, monospace',
}

const fonts = {
  ...baseFonts,
  body: baseFonts.serif,
  heading: baseFonts.sans,
  monospace: baseFonts.mono,
}

const sansHeading = {
  fontFamily: baseFonts.sans,
  fontWeight: 600,
}

const h5 = {
  ...sansHeading,
  fontSize: tailwind.fontSizes.sm,
  color: `heading`,
  textTransform: `uppercase`,
  letterSpacing: `.15em`,
}

const navlink = {
  ...sansHeading,
  letterSpacing: `.15em`,
  textTransform: `uppercase`,
  fontSize: tailwind.fontSizes.sm,
  lineHeight: 1,
  transition: `all 0.3s ease-in-out`,
  [`&.active`]: {
    color: `primary`,
  }
}

const pageMaxWidth = `1200px`

export default merge(tailwind, {
  initialColorModeName: `light`,
  config: {
    useCustomProperties: true,
  },
  colors: {
    text: tailwind.colors.gray[8],
    primary: tailwind.colors.violet[5],
    background: tailwind.colors.white,
    backgroundPattern: `#F1F4F7`,
    iconPrimary: tailwind.colors.gray[8],
    iconSecondary: tailwind.colors.gray[6],
    textMuted: tailwind.colors.gray[7],
    heading: tailwind.colors.gray[8],
    toggleIcon: tailwind.colors.black,
    muted: tailwind.colors.gray[3]
  },
  alerts: {
    success: {
      fontFamily: baseFonts.sans,
      bg: tailwind.colors.emerald[3]
    },
    error: {
      fontFamily: baseFonts.sans,
      bg: candy.red
    }
  },
  buttons: {
    primary: {
      ...h5,
      color: tailwind.colors.white,
      cursor: `pointer`
    }
  },
  fonts,
  forms: {
    label: {
      ...h5,
      mb: 2,
    }
  },
  layout: {
    footer: {
      display: `block`,
      color: `text`,
      pt: 6,
      pb: 4,
    },
    main: {
      position: `relative`,
      maxWidth: pageMaxWidth,
      margin: `0 auto`
    },
    header: {
      position: `relative`,
      overflow: `hidden`,
      marginX: [4, 4, 4, 4, `auto`],
      maxWidth: [`none`, `none`, pageMaxWidth],
      '> div': {
        mt: [4, 4, 5],
        mb: [5, 5, 6],
        zIndex: 10,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
      }
    },
    nav: {
      justifyContent: `center`,
      alignItems: [`center`, `center`, `inherit`],
      flexDirection: [`column`, `column`, `row`],
      position: [`fixed`, `fixed`, `static`],
      zIndex: 100,
      left: [`auto`, `auto`, 4, 5],
      right: [`100%`, `100%`, `auto`],
      transition: `right 0.45s ease-in-out`,
      top: [`0`, `0`, `auto`],
      width: [`100%`, `100%`, `auto`],
      height: [`100%`, `100%`, `auto`],
      background: [`background`, `background`, `transparent`],
      '&>a': {
        textDecoration: `none`,
        marginX: `1rem`,
        paddingTop: [3, 3, 0],
        paddingBottom: [3, 3, 1],
        '&:last-of-type': {
          marginRight: [`1rem`, `1rem`, 0]
        },
        ...navlink,
        color: `text`,
        '&:hover': {
          textDecoration: `none`,
          color: `primary`
        }
      }
    },
    navMenuButton: {
      display: [`block`, `block`, `none`],
      position: `relative`,
      zIndex: 200,
      button: {
        cursor: `pointer`,
        width: `auto`,
        height: `auto`
      },
      svg: {
        strokeWidth: 1
      }
    },
    projectHead: {
      position: `relative`,
      overflow: `hidden`,
      justifyContent: `center`,
    },
    container: {
      px: [4, null, null, null, 0],
      py: [2, 3],
      maxWidth: pageMaxWidth,
    },
  },
  pagination: {
    a: {
      ...h5,
      textDecoration: `none`,
      svg: {
        strokeWidth: 1
      }
    }
  },
  styles: {
    root: {
      color: `text`,
      backgroundColor: `background`,
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
    },
    a: {
      color: `primary`,
      textDecoration: `none`,
      transition: `all 0.3s ease-in-out`,
    },
    navlink: {
      ...navlink
    },
    p: {
      fontSize: tailwind.fontSizes.default,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
    },
    brand: {
      fontSize: [tailwind.fontSizes["4xl"], tailwind.fontSizes["5xl"]],
      mt: 0,
      color: `heading`,
      textTransform: `lowercase`,
      textDecoration: `none`,
      display: `inline-flex`,
      '&>a': {
        textDecoration: `none`,
        color: `heading`,
        display: `inline-flex`
      },
      svg: {
        width: [`80px`, `80px`, `auto`],
        height: [`80px`, `80px`, `auto`],
      }
    },
    hero: {
      fontSize: [tailwind.fontSizes["2xl"], tailwind.fontSizes["3xl"], tailwind.fontSizes["5xl"]],
      mt: 0,
      textDecoration: `none`,
      display: `inline-flex`,
      '&>a': {
        textDecoration: `none`,
        color: tailwind.colors.white,
        display: `inline-flex`
      },
    },
    h1: {
      fontSize: [tailwind.fontSizes["2xl"], tailwind.fontSizes["3xl"]],
      mt: 2,
      color: `heading`,
      letterSpacing: `0.05em`,
    },
    h2: {
      fontSize: [tailwind.fontSizes["lg"], tailwind.fontSizes["xl"]],
      mt: 2,
      color: `heading`,
      letterSpacing: `.15em`,
    },
    h3: {
      fontSize: [tailwind.fontSizes["xl"], tailwind.fontSizes["2xl"]],
      mt: 3,
      color: `heading`,
    },
    h4: {
      fontWeight: 600,
      fontSize: [tailwind.fontSizes.default, tailwind.fontSizes.lg],
      color: `heading`,
      letterSpacing: `.15em`,
      textTransform: `uppercase`,
    },
    h5: {
      ...h5,
    },
    h6: {
      fontSize: tailwind.fontSizes.default,
      mb: 2,
      color: `heading`,
    },
  },
})
