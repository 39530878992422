export const candy = {
  barbiePink: `#fb65a4`,
  cottonCandy: `#fdb3ea`,
  red: `#E65A3C`,
  fanta: `#FA7040`,
  marigold: `#F1C653`,
  green: `#52B39C`,
  hawaiianShallows: `#a3fdee`,
  lilac: `#a67fea`,
  grape: `#9460f1`,
}
